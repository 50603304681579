export class InboundPriceUpdate {
  product_id;
  inbound_id;
  purchase_price_ht;
  purchase_tva = "0.00";
  discount = 0;
  price;
  tva = "0.00";
  should_update_price = false;
}
