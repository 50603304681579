<template>
  <v-container
    fluid
    id="regular-table"
    class="mt-4"
  >
    <v-form
      ref="form"
      v-model="is_valid"
      lazy-validation
    >
      <div
        class="d-flex flex-column flex-md-row mx-2"
        style="gap: 80px"
      >
        <div class="flex-grow-1">
          <h4 class="text-h4 mb-4">{{ $t("order_details") }}</h4>
          <div class="ml-auto me-6">
            <div
              class="text-h5 d-flex justify-space-between pa-4"
              style="min-width: 200px"
            >
              <span>{{ $t("shelf") }}</span>
              <span>{{ inbound?.supplyOrder?.shelf?.name ?? "-" }}</span>
            </div>
            <v-divider></v-divider>
            <div
              class="text-h5 d-flex justify-space-between pa-4"
              style="min-width: 200px"
            >
              <span>{{ $t("provider") }}</span>
              <span>{{ inbound?.fromProvider?.name ?? "-" }}</span>
            </div>
            <v-divider></v-divider>
          </div>
          <div
            class="ml-auto me-6"
            v-if="inbound?.supplyOrder"
          >
            <div
              class="text-h5 d-flex justify-space-between pa-4"
              style="min-width: 200px"
            >
              <span>{{ $t("supply_order_id") }}</span>

              <router-link
                v-if="$admin.can('supplyOrder-view')"
                :to="{
                  name: 'supplyOrders.view',
                  params: { id: inbound?.supplyOrder?.id },
                }"
                target="_blank"
              >
                {{ inbound?.supplyOrder?.tracking_number ?? "-" }}
              </router-link>
              <span v-else>
                {{ inbound?.supplyOrder?.tracking_number ?? "-" }}
              </span>
            </div>
            <v-divider></v-divider>
          </div>
          <div
            class="ml-auto me-6"
            v-if="inbound?.supplyOrder"
          >
            <div
              class="text-h5 d-flex justify-space-between pa-4"
              style="min-width: 200px"
            >
              <span>{{ $t("purchase_order") }}</span>
              <v-chip
                small
                color="primary"
                class="my-auto"
                :disabled="is_loading_invoice"
                :loading="is_loading_invoice"
                @click="downloadPurchaseOrder(inbound?.supplyOrder)"
              >
                {{ "order-" + inbound?.supplyOrder?.tracking_number + ".pdf" }}
                <v-icon right> mdi-eye </v-icon></v-chip
              >
            </div>
            <v-divider></v-divider>
          </div>
          <h3 class="text-h4 mb-4 mt-16">
            {{ $t("pricing_details") }}
          </h3>
          <v-row>
            <v-col
              sm="6"
              md="6"
            >
              <v-text-field
                type="number"
                dense
                outlined
                :label="$t('invoice_amount_ht')"
                v-model="inbound.invoice_amount_ht"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              sm="6"
              md="6"
            >
              <v-text-field
                type="number"
                dense
                outlined
                :label="$t('invoice_amount_ttc')"
                v-model="inbound.invoice_amount_ttc"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n3">
            <v-col
              sm="6"
              md="6"
            >
              <div
                class="text-h5 d-flex justify-space-between"
                style="min-width: 100px"
              >
                <v-span>{{ $t("receipt_amount_ht") }}</v-span>
                <v-span>{{ totalItemsPriceHt() }}</v-span>
              </div>
              <v-divider></v-divider>
            </v-col>
            <v-col
              sm="6"
              md="6"
            >
              <div class="text-h5 d-flex justify-space-between">
                <v-span>{{ $t("receipt_amount_ttc") }}</v-span>
                <v-span>{{ totalItemsPrice() }}</v-span>
              </div>
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </div>
        <div class="flex-grow-1">
          <h4 class="text-h4 mb-4">{{ $t("order_details") }}</h4>
          <div class="ml-auto me-6">
            <div
              class="text-h5 d-flex justify-space-between pa-4"
              style="min-width: 200px"
            >
              <span>{{ $t("delivery_type") }}</span>
              <span>{{ inbound?.supplyOrder?.delivery_type ?? "-" }}</span>
            </div>
            <v-divider></v-divider>
            <div
              class="text-h5 d-flex justify-space-between pa-4"
              style="min-width: 200px"
            >
              <span>{{ $t("hub") }}</span>
              <span>{{ inbound?.toHub?.name }}</span>
            </div>
            <v-divider></v-divider>
            <div
              class="text-h5 d-flex justify-space-between pa-4"
              style="min-width: 200px"
            >
              <span>{{ $t("delivery_date") }}</span>
              <span>{{ inbound?.supplyOrder?.expected_delivery_date }}</span>
            </div>
            <v-divider class="mb-10"></v-divider>
            <div
              class="text-h5 d-flex mt-n6"
              style="min-width: 150 px"
            >
              <v-text-field
                dense
                outlined
                :label="$t('invoice_number_*')"
                v-model="inbound.invoice_number"
                :rules="[rules.required]"
              ></v-text-field>
            </div>
            <div
              class="text-h5 d-flex"
              style="min-width: 150 px"
            >
              <v-text-field
                dense
                outlined
                :label="$t('delivery_order_number_*')"
                v-model="inbound.delivery_order_number"
                :rules="[rules.required]"
              ></v-text-field>
            </div>
          </div>
        </div>
      </div>
      <v-container fluid>
        <v-card
          elevation="0"
          class="mt-4"
        >
          <h4 class="text-h4 py-2">
            {{ $t("items_list") }} ({{ inbound.products?.length }})
          </h4>

          <v-data-table
            show-expand
            single-expand
            :headers="cart_headers"
            item-key="name"
            :items="listProducts"
            class="elevation-0"
            :footer-props="{ 'items-per-page-options': [5, 10] }"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                class="grey"
              >
                <v-simple-table
                  class="my-5 grey"
                  dense
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">{{ $t("product_id") }}</th>
                        <th class="text-left">{{ $t("barcode") }}</th>
                        <th class="text-left">
                          {{ $t("pcb") }}
                        </th>
                        <th class="text-left">{{ $t("discount") }}</th>
                        <th class="text-left">
                          {{ $t("discount_price") }}
                        </th>
                        <th class="text-left">{{ $t("tva") }}</th>
                        <th class="text-left">{{ $t("total_price") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ item.generated_code ?? item.code }}</td>
                        <td>{{ item.barcode }}</td>
                        <td>
                          <span v-if="item.qte_in_packaging > 1">
                            {{ item.qte_in_packaging }}
                          </span>
                          <span v-else> - </span>
                        </td>
                        <td>{{ item.discount }}</td>
                        <td>{{ calcProductDiscount(item) }}</td>
                        <td>{{ item.inbound_tva }}</td>
                        <td>{{ calcProductPrice(item) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
            <template v-slot:top> </template>
            <template v-slot:item="{ item, expand, isExpanded }">
              <tr>
                <td>
                  <v-icon @click="expand(!isExpanded)">{{
                    isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </td>
                <td>
                  <template>
                    <v-img
                      v-if="item.image"
                      :src="item.image.url"
                      max-height="50"
                      max-width="50"
                    />
                    <v-img
                      v-else
                      src="@/assets/product.png"
                      max-height="50"
                      max-width="50"
                    />
                  </template>
                </td>
                <td>{{ item.name }}</td>
                <td>
                  <template>
                    <span v-if="item.qte_in_packaging > 1">
                      {{ item.qte_by_type_packaging }}
                    </span>
                    <span v-else> - </span>
                  </template>
                </td>
                <td>{{ item.qte_in_supply_order }}</td>
                <td>{{ item.inbound_price_ht }}</td>
                <td>
                  <template>
                    <span v-if="item.discount > 0">
                      {{ item.discount + "%" }}
                    </span>
                    <span v-else>0,00 % </span>
                  </template>
                </td>

                <td>{{ calcProductDiscountedPriceHT(item) }}</td>
                <td>
                  <template>
                    <v-text-field
                      class="my-1"
                      v-if="$admin.can('inbound-update')"
                      outlined
                      dense
                      hide-details="auto"
                      type="text"
                      v-model="batches[item.id][0].qte_in_lot"
                      :rules="[rules.min_quantity(0), rules.required]"
                      @input="prepareBatch(item)"
                    ></v-text-field>
                  </template>
                </td>
                <td>
                  <template>
                    <v-text-field
                      class="my-1"
                      v-if="$admin.can('inbound-update')"
                      outlined
                      dense
                      hide-details="auto"
                      type="text"
                      v-model="batches[item.id][0].lot_number"
                      @input="
                        prepareBatch(item),
                          checkExistingBatch(
                            batches[item.id][0].lot_number,
                            0,
                            item
                          )
                      "
                      :rules="[
                        (value) => !lotStatus || $t('lot_number_unique'),
                      ]"
                    ></v-text-field>
                  </template>
                </td>

                <td>
                  <template>
                    <v-menu
                      v-model="show_expiration_date_picker[item.id]"
                      :close-on-content-click="false"
                      transition="slide-y-transition"
                      min-width="auto"
                      left
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          class="mb-n6 py-1"
                          dense
                          v-model="batches[item.id][0].expiration_date"
                          append-icon="mdi-calendar"
                          readonly
                          chips
                          v-bind="attrs"
                          v-on="on"
                          v-if="$admin.can('inbound-update')"
                          :disabled="batches[item.id][0].qte_in_lot == 0"
                          :rules="[
                            (value) => {
                              return (
                                batches[item.id][0].qte_in_lot == 0 ||
                                new Date().getTime() <
                                  new Date(value).getTime() ||
                                'Date should be superior to the current date'
                              );
                            },
                          ]"
                          @input="prepareBatch(item)"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="batches[item.id][0].expiration_date"
                        no-title
                        color="primary"
                        class="my-0 py-4"
                        @input="
                          (show_expiration_date_picker[item.id] = false),
                            prepareBatch(item)
                        "
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </td>
                <td>
                  <template>
                    <v-btn
                      fab
                      x-small
                      outlined
                      color="primary"
                      @click="addBatches(item)"
                      ><v-icon> mdi-plus</v-icon>
                    </v-btn>
                  </template>
                </td>
                <td>
                  <template>
                    <v-icon
                      v-if="$admin.can('inbound-update-price')"
                      class="mr-2"
                      @click="editPrice(item)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                </td>
              </tr>
              <tr
                v-for="(lot, index) in batches[item.id]"
                v-bind:key="'lot' + index + '-' + item.id"
              >
                <template v-if="index > 0">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <template>
                      <v-text-field
                        class="my-1"
                        v-if="$admin.can('inbound-update')"
                        outlined
                        dense
                        hide-details="auto"
                        type="text"
                        v-model="batches[item.id][index].qte_in_lot"
                        :rules="[rules.min_quantity(0)]"
                        @input="prepareBatch(item)"
                      ></v-text-field>
                    </template>
                  </td>
                  <td>
                    <template>
                      <v-text-field
                        class="my-1"
                        v-if="$admin.can('inbound-update')"
                        outlined
                        dense
                        hide-details="auto"
                        type="text"
                        v-model="batches[item.id][index].lot_number"
                        @input="
                          prepareBatch(item),
                            checkExistingBatch(
                              batches[item.id][index].lot_number,
                              index,
                              item
                            )
                        "
                        :rules="[
                          (value) => !lotStatus || $t('lot_number_unique'),
                        ]"
                      ></v-text-field>
                    </template>
                  </td>
                  <td>
                    <template>
                      <v-menu
                        v-model="
                          show_expiration_date_picker[item.id + '_' + index]
                        "
                        :close-on-content-click="false"
                        transition="slide-y-transition"
                        min-width="auto"
                        left
                        offset-y
                      >
                        <template
                          v-slot:activator="{ on, attrs }"
                          v-if="index > 0"
                        >
                          <v-text-field
                            outlined
                            class="mb-n6 py-1"
                            dense
                            v-model="batches[item.id][index].expiration_date"
                            append-icon="mdi-calendar"
                            readonly
                            chips
                            v-bind="attrs"
                            v-on="on"
                            v-if="$admin.can('inbound-update')"
                            :disabled="batches[item.id][index].qte_in_lot == 0"
                            :rules="[
                              (value) => {
                                return (
                                  batches[item.id][index].qte_in_lot == 0 ||
                                  new Date().getTime() <
                                    new Date(value).getTime() ||
                                  'Date should be superior to the current date'
                                );
                              },
                            ]"
                            @input="prepareBatch(item)"
                          ></v-text-field>
                        </template>

                        <v-date-picker
                          v-model="batches[item.id][index].expiration_date"
                          no-title
                          color="primary"
                          class="my-0 py-4"
                          @input="
                            (show_expiration_date_picker[
                              item.id + '_' + index
                            ] = false),
                              prepareBatch(item)
                          "
                        ></v-date-picker>
                      </v-menu>
                    </template>
                  </td>
                  <td>
                    <template>
                      <v-btn
                        text
                        class="mx-n7"
                        @click="() => removeBatch(index, item)"
                      >
                        <v-icon> $vuetify.icons.iconDeleteItem</v-icon>
                      </v-btn>
                    </template>
                  </td>
                  <td></td>
                </template>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <th class="grey--text">
                  {{ $t("total_received") }}
                </th>
                <td>
                  <span>{{ totalQuantities(item).toFixed(2) }} </span>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </template>
          </v-data-table>

          <label>{{ $t("last_edited") + " : " + inbound.updated_at }} </label>
          <a
            text
            @click="show_details = !show_details"
          >
            {{ show_details ? $t("hide_details") : $t("show_details") }}
            <v-icon>{{
              show_details ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </a>
          <v-expand-transition>
            <v-col
              cols="8"
              v-show="show_details"
            >
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">{{ $t("product_name") }}</th>
                      <th class="text-left">{{ $t("updated_by") }}</th>
                      <th class="text-left">{{ $t("updated_at") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="product in inbound.products">
                      <tr
                        v-for="history in product.prices_history"
                        :key="history.update_at"
                      >
                        <td>{{ product.name }}</td>
                        <td>{{ history.updated_by }}</td>
                        <td>{{ history.updated_at }}</td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-expand-transition>
        </v-card>
      </v-container>
      <div class="mt-5 d-flex justify-space-between">
        <v-btn
          outlined
          color="primary"
          class="mr-auto"
          @click="returnToList()"
        >
          {{ $t("back") }}
        </v-btn>

        <v-btn
          v-if="$admin.can('inbound-update')"
          outlined
          color="primary"
          :disabled="!inbound.products || inbound.products <= 0"
          @click="saveChange(inbound)"
        >
          {{ this.$t("save_change") }}
        </v-btn>

        <v-btn
          v-if="$admin.can('inbound-update-status')"
          class="primary mx-5"
          :disabled="!inbound.products || inbound.products <= 0"
          @click="toggleGenerateInbound()"
        >
          <span v-if="inbound.received_at">
            {{ this.$t("update_inbound") }}
          </span>
          <span v-else>{{ this.$t("generate_inbound") }}</span>
        </v-btn>
      </div>
    </v-form>
    <generate-inbound-modal
      :isGenerateInboundModalOpen="isGenerateInboundModalOpen"
      :toggleGenerateInbound="toggleGenerateInbound"
      :generateInbound="generateInbound"
      :currentInbound="inbound"
    ></generate-inbound-modal>
    <update-price-modal
      :isModalOpen="isUpdatePriceModalOpen"
      :toggleModal="toggleUpdatePrice"
      :updatePrice="updatePrice"
      :inboundItem="currentProduct"
    ></update-price-modal>
  </v-container>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";
import inboundProductService from "@/store/services/inbound-product-service";
import { InboundPriceUpdate } from "./Inbound.class";
import supplyOrderService from "@/store/services/supply-order-service";

export default {
  mixins: [validationRules],
  components: {
    GenerateInboundModal: () => import("./components/GenerateInboundModal.vue"),
    UpdatePriceModal: () => import("./components/UpdatePriceModal.vue"),
  },

  computed: {
    ...mapGetters({
      shelves: "categories/listShelves",
      isLoadingShelves: "categories/isLoadingCategories",
      isLoadingHubs: "hubs/isLoadingHubs",
      hubs: "hubs/listHubs",
      isLoadingProviders: "providers/isLoadingProviders",
      listProviders: "providers/listProviders",
    }),
    inbound: {
      get() {
        return this.$store.state.inboundProducts.inbound;
      },
      set(value) {
        this.$store.commit("inboundProducts/SET_INBOUND", value);
      },
    },

    listProducts: {
      get() {
        return this.$store.state.inboundProducts.inbound.products || [];
      },
      set(value) {
        this.$store.commit("inboundProducts/SET_PRODUCTS", value);
      },
    },
    listProductsLength() {
      return this.listProducts.length;
    },
  },
  data() {
    return {
      lotStatus: false,
      is_loading_invoice: false,
      show_details: false,
      show_expiration_date_picker: [],
      products_filter: {},
      is_valid: true,
      cleanList: null,
      currentOrderItem: null,
      isModalOpen: false,
      isGenerateInboundModalOpen: false,
      isUpdatePriceModalOpen: false,
      currentProduct: null,
      batches: [],
      test: null,

      cart_headers: [
        { text: this.$t("image"), value: "image", align: "start" },
        {
          text: this.$t("name"),
          sortable: false,
          value: "name",
        },
        { text: this.$t("ordered_packages"), value: "qte_by_type_packaging" },
        {
          text: this.$t("ordered_qte"),
          value: "qte_in_supply_order",
          sortable: false,
          width: 80,
        },
        {
          text: this.$t("unit_price_ht"),
          value: "inbound_price_ht",
          sortable: false,
          width: 80,
        },
        {
          text: this.$t("discount_%"),
          value: "discount",
          sortable: false,
          width: 80,
        },
        {
          text: this.$t("total_price_ht"),
          value: "total_price_ht",
          sortable: false,
        },

        {
          text: this.$t("received_quantity"),
          value: "qte_in_lot",
          sortable: false,
          width: 100,
        },
        { text: this.$t("batch"), value: "batches", width: 160 },
        {
          text: this.$t("expiration_date"),
          value: "expiration_date",
          width: 160,
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "add",
        },
        {
          text: this.$t("actions"),
          value: "actions",
        },
      ],
    };
  },

  watch: {
    listProductsLength() {
      this.listProducts.forEach((item) => {
        if (item.batches.length == 0) {
          this.batches[item.id] = [
            {
              lot_number: null,
              qte_in_lot: 0,
              expiration_date: null,
            },
          ];
        } else {
          this.batches[item.id] = item.batches;
        }
      });
    },
  },

  methods: {
    totalItemsPrice() {
      const sum = this.listProducts
        .map((item) => {
          return parseFloat(this.calcProductPrice(item));
        })
        .reduce((a, b) => a + b, 0);
      return sum < 0 ? 0 : sum.toFixed(2);
    },
    totalItemsPriceHt() {
      const sum = this.listProducts
        .map((item) => {
          return parseFloat(this.calcProductDiscountedPriceHT(item));
        })
        .reduce((a, b) => a + b, 0);
      return sum < 0 ? 0 : sum.toFixed(2);
    },
    totalQuantities(item) {
      const item_id = item.id;
      const Batches = this.batches[item_id];
      var total = 0;
      Batches?.forEach((element) => {
        if (element.qte_in_lot == null) element.qte_in_lot == 0;
        const quantite = parseInt(element.qte_in_lot);
        total += quantite;
      });
      return total;
    },

    checkExistingBatch(lotNumber, batchIndex, item) {
      const item_id = item.id;
      const newArray = this.batches[item_id].filter(
        (value, index) => index !== batchIndex
      );
      const lotExists1 = newArray.some((item) => item.lot_number === lotNumber);
      if (lotExists1) {
        this.lotStatus = true;
      } else {
        this.lotStatus = false;
        for (let i = 0; i < this.listProducts.length; i++) {
          if (i !== this.listProducts.indexOf(item)) {
            const lotExists2 = this.listProducts[i].batches.some(
              (item) => item.lot_number === lotNumber
            );
            if (lotExists2) {
              this.lotStatus = true;
            }
          }
        }
      }
    },
    prepareBatch(item) {
      const item_id = item.id;
      const Batches = this.batches[item_id];
      item.batches = Batches;
      this.$set(this.batches, this.batches.length, Batches);
    },
    addBatches(item) {
      const item_id = item.id;
      const Batches = this.batches[item_id];
      if (Batches) {
        Batches.push({
          lot_number: null,
          qte_in_lot: 0,
          expiration_date: null,
        });
      }
      this.$set(this.batches, this.batches.length, Batches);
    },
    removeBatch(batchIndex, item) {
      const item_id = item.id;
      this.batches[item_id] = this.batches[item_id].filter(
        (value, index) => index !== batchIndex
      );
      const Batches = this.batches[item_id];
      this.$set(this.batches, this.batches.length, Batches);
      item.batches = Batches;
    },
    async init() {},

    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },

    openOrderItemModal(item) {
      this.currentOrderItem = item;
      this.isModalOpen = true;
    },

    async updateInbound(inbound) {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch("inboundProducts/update", inbound);
      }
    },

    toggleGenerateInbound() {
      if (this.$refs.form.validate())
        this.isGenerateInboundModalOpen = !this.isGenerateInboundModalOpen;
    },
    toggleUpdatePrice() {
      this.isUpdatePriceModalOpen = !this.isUpdatePriceModalOpen;
    },

    editPrice(item) {
      item.product_id = item.id;
      item.inbound_id = this.inbound.id;

      this.currentProduct = item;
      this.toggleUpdatePrice();
    },

    async updatePrice(product) {
      try {
        this.toggleUpdatePrice();
        product.should_update_price = product.should_update_price ? 1 : 0;
        const inboundPriceUpdateDTO = new InboundPriceUpdate();
        Object.keys(inboundPriceUpdateDTO).forEach((key) => {
          inboundPriceUpdateDTO[key] = product[key];
        });

        await this.$store.dispatch(
          "inboundProducts/updateProductPrices",
          inboundPriceUpdateDTO
        );
        this.$store.dispatch("alerts/success", this.$t("updated_successfully"));
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
    async confirmInbound(inbound) {
      await this.$store.dispatch("inboundProducts/confirm", inbound);
    },

    returnToList() {
      this.$router.push({
        name: "inbound.products",
      });
    },

    async generateInbound(inbound) {
      if (this.$refs.form.validate()) {
        this.is_loading = true;

        await this.confirmInbound(inbound);
        await this.downloadInvoice(inbound);

        this.returnToList();
        this.is_loading = false;
      }
    },

    async saveChange() {
      if (this.$refs.form.validate()) {
        try {
          this.is_loading = true;
          this.inbound.inbound_id = this.inbound.id;
          await this.updateInbound(this.inbound);
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.returnToList();
          this.is_loading = false;
        } catch (error) {
          this.is_loading = false;
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },

    async downloadPurchaseOrder(order) {
      console.log(order);
      this.is_loading_invoice = true;
      await supplyOrderService
        .getInvoice(order)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "order-" + order?.tracking_number + ".pdf"
          );
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_invoice = false;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_invoice = false;
        });
    },

    downloadInvoice(item) {
      this.$store
        .dispatch("inboundProducts/getInvoice", item)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "inbound-" + item.tracking_number + ".pdf"
          );
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_invoice = false;
        })
        .catch((error) => {
          throw error;
        });
    },

    calcProductPrice(item) {
      const result =
        parseFloat(this.calcProductDiscountedPriceHT(item)) +
        parseFloat(this.calcProductTVA(item));
      return Math.round(result * 100) / 100;
    },

    calcProductPriceHT(item) {
      return parseFloat(item.inbound_price_ht) * this.totalQuantities(item);
    },

    calcProductDiscount(item) {
      return (
        Math.round(this.calcProductPriceHT(item) * parseFloat(item.discount)) /
        100
      );
    },

    calcProductDiscountedPriceHT(item) {
      return (
        Math.round(
          this.calcProductPriceHT(item) *
            (1 - parseFloat(item.discount) / 100) *
            100
        ) / 100
      );
    },

    calcProductTVA(item) {
      return (
        Math.round(
          this.calcProductDiscountedPriceHT(item) * parseFloat(item.inbound_tva)
        ) / 100
      );
    },

    loaderPriceSettings() {
      return this.$store.dispatch("settings/getPriceSettings");
    },
  },

  async created() {
    this.loaderPriceSettings();
    this.$store.commit("inboundProducts/SET_INBOUND", {});
    this.$store.commit("products/CLEAN_LIST");
    await this.init();
    const id = this.$route.params.id;
    if (id) {
      const inboundEntity = await inboundProductService.show({
        id,
      });
      this.$store.commit("inboundProducts/SET_INBOUND", inboundEntity);
    }
  },
};
</script>
<style scoped>
.v-input {
  font-size: 0.95em;
}
.v-application .grey {
  background-color: #f9f9f9 !important;
  border-color: #f9f9f9 !important;
}
.v-input >>> input[type="number"] {
  -moz-appearance: textfield;
}
.v-input >>> input::-webkit-outer-spin-button,
.v-input >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.v-data-table__expanded__content {
  box-shadow: none !important;
}
.v-data-table__expanded__content * {
  background-color: #f9f9f9;
}
</style>
